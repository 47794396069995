<template>
    <div class="subContain" v-loading="isRequestLoadingCompany">
        <div class="storeInfo">
            <h2 class="name">{{companyName}}</h2>

            <el-button v-if="companyData.isAttention === 1" @click="toAttention">关注</el-button>
            <el-button class="on" v-if="companyData.isAttention === 2" @click="toCancelAttention">已关注</el-button>
        </div>

        <div class="saleInfo">
            <h1 class="title">采购记录</h1>
            <span class="text">采购计划：<span>{{companyData.purchaseNum}}</span> 条</span>
        </div>

        <div class="saleInfo">
            <h1 class="title">采购商还在采购这些</h1>

            <div class="item" v-for="(item, index) in companyData.purchase" :key="index">
                <span class="link" @click="goSupplyBuyingDetail(item.id, 1)">{{item.title}}</span>
                <span class="text">采购量：{{item.num}}{{item.unitName}}</span>
            </div>
        </div>

        <div class="saleInfo">
            <h1 class="title">供应记录</h1>
            <span class="text">供应计划：<span>{{companyData.provisionNum}}</span> 条</span>
        </div>

        <div class="saleInfo">
            <h1 class="title">商家还在供应这些</h1>
            <div class="item" v-for="(item, index) in companyData.provision" :key="index">
                <span class="link" @click="goSupplyBuyingDetail(item.id, 2)">{{item.title}}</span>
                <span class="text">采购量：{{item.num}}{{item.unitName}}</span>
            </div>
        </div>
    </div>
</template>

<script>
  import {getBuyingCompany, postCompanyAttention} from "../../../../api/shop/supplyBuying";

  export default {
    name: "companySide",
    props:{
      companyId: {
        type: String,
        default: ''
      },
      companyName: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        companyData: {},
        isRequestLoadingCompany: false,
      }
    },
    created() {
      this.getCompanyInfo()
    },
    methods: {
      getCompanyInfo () {
        this.isRequestLoadingCompany = true
        getBuyingCompany(this.companyId).then(res => {
          this.isRequestLoadingCompany = false
          if (res.code === 0) {
            this.companyData = res.data
          }
        }).catch(() => {
          this.isRequestLoadingCompany = false
        })
      },
      goSupplyBuyingDetail (id, type) {
        this.$emit('goSupplyBuyingDetail', {id: id, type: type})
      },
      toAttention () {
        postCompanyAttention(this.companyId, 2).then(res => {
          if (res.code === 0) {
            this.companyData.isAttention = 2
          }
        })
      },
      toCancelAttention () {
        this.$confirm('是否确定不在关注此企业信息？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postCompanyAttention(this.companyId, 1).then(res => {
            if (res.code === 0) {
              this.companyData.isAttention = 1
            }
          })
        }).catch(() => {});
      },
    }
  }
</script>
